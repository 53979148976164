<template>
  <login-content>
    <div class="logincont">
      <div class="title">验证码登录</div>
      <check-phone :type="1" @confirm="loginHandler" />
      <button
        class="com-button com-button-switch mar"
        @click="$router.replace($route.meta.inject ? '/inject/login/password' : '/login/password')"
      >
        账号密码登录
      </button>
    </div>
  </login-content>
</template>

<script>
import CheckPhone from "@/components/sendcode/Index.vue";
import { loginSms } from "@/api/user.js";
import { _debounce } from "@/tool";
import { mapState } from "vuex";

export default {
  components: { CheckPhone },
  data() {
    this.loginHandler = _debounce(this.loginHandlerFn, 1000);
    return {};
  },
  computed: mapState({ params: (state) => state.meta.params }),
  mounted() {
    console.log(this.$route.meta);
  },
  methods: {
    async loginHandlerFn(data) {
      const res = await loginSms({
        phone: data.mobile,
        shortCode: data.code
      });
      if (res.success) {
        if (this.$route.meta.inject) {
          window.location.replace(decodeURIComponent(this.params.redirect_uri));
          return;
        }
        if (res.data.location === "/") {
          this.$router.replace("/user");
          return;
        }
        // 若用户存在，重定向
        window.location.replace(res.data.location);
        return;
      }
      if (res.code === 9002004) {
        // 若用户不存在，进行注册
        const userId = res.data;
        localStorage.setItem("userId", userId);
        this.$router.replace(this.$route.meta.inject ? "/inject/register" : "/register");
      } else {
        this.$message.error(res.message);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.mar {
  margin-top: 16px !important;
  margin-bottom: 0 !important;
}
</style>
